<template>
  <div>
    <van-nav-bar title="登录" style=""/>
    <div style="height:40px"></div>
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field v-model="userID" label='用户名' name='ID' type="digit" required placeholder='请输入员工号'
        :rules="[{ required: true, message: '请输入用户名' }]" />
        <van-field v-model="userPwd" style="Ime-mode:disabled" label='密码' name='Password' :type='passwordVisible?"text":"password"'
          :right-icon='passwordVisible?"eye":"closed-eye"' required placeholder='请输入密码' @click-right-icon="passwordVisible=!passwordVisible"
          :rules="[{ required: true, message: '请输入密码' }]"/>
      </van-cell-group>
      <div style="margin:20px">
        <van-button type='primary' block round native-type="submit">登录</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { ref } from 'vue'
import { showLoadingToast, closeToast, showFailToast } from 'vant'
export default {
  setup () {
    const userID = ref('')
    const userPwd = ref('')
    const timer = ref() // 定时器，点击登录超过一定时间后的定时器
    const loginFailed = () => { // 如果登录超时，则提示登录失败
      clearTimeout(timer.value)
      closeToast()
      showFailToast('登录失败')
    }
    const onSubmit = () => {
      showLoadingToast({
        duration: 0,
        forbidClick: true,
        message: '正在登录，请稍后'
      })
      timer.value = setTimeout(loginFailed, 10000)
    }

    return {
      userID,
      userPwd,
      passwordVisible: ref(false),
      onSubmit
    }
  }
}
</script>

<style scoped lang="less">
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
</style>
