<template>
  <div class="layout">
    <el-container>
      <el-aside width="300px">
        <div style="flex-grow:1">
          <el-avatar shape="circle" :size="150" fit="cover" :src="avatar" ></el-avatar>
          <h2>布nana</h2>
          <div style="text-align:center">
            <el-popover placement="top-start" trigger="hover" :width="210">
              <template #reference>
                <img src="@/assets/images/vx.svg" style="margin:0 10px 0px 10px; width: 24px; height: 24px;"/>
              </template>
              <img src="@/assets/images/vxQrCode.png" style="width:180px;heihgt:180px"/>
            </el-popover>
            <el-popover placement="top-start" trigger="hover" :width="210" content="xiao_xiangyu@126.com">
              <template #reference>
                <img src="@/assets/images/EML.svg" style="margin:0 10px 0px 10px; width: 24px; height: 24px;"/>
              </template>
            </el-popover>
          </div>
          <div class="introduction">业余编程爱好者，喜欢通过编程实现自己的想法，提高工作或者生活效率。
            喜欢了解各种先进技术，虽然自己不太在行。</div>
          <div class="introduction">本站用于展示个人的做的一些小软件、小系统，没有目标受众，若有缘得见，可就此进行交流。</div>
        </div>
        <div style="height:30px; padding:10px 0">
          <el-link href="http://beian.miit.gov.cn" target="_blank">苏ICP备2023011901号-1</el-link>
        </div>
      </el-aside>
      <el-main></el-main>
    </el-container>
  </div>
</template>

<script>
import avatar from '@/assets/images/Avatar.jpg'
export default {
  setup () {
    return {
      avatar
    }
  }
}
</script>

<style lang="less">
.layout{
  .el-container{
    height: 100%;
  }
  .el-aside{
    border-right:lightgray 1px solid;
    background-color: #F6F6F6;
    box-shadow: 0px 0px 10px gray;
    display: flex;
    flex-direction: column;
  }

  .el-avatar{
    margin-top:60px;
    border: 2px lightblue solid;
    box-shadow: 0px 0px 10px lightblue;
  }

  .introduction{
    color: gray;
    text-align: left;
    padding: 10px;
    font-size: 14px;
    text-indent: 2em;
  }

  height: 100%;
}
</style>
