<template>
  <div style="height:100%;width:100%">
    <router-view></router-view>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { ref, defineComponent } from 'vue'

export default defineComponent({
  setup () {
    return {
    }
  }
})
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

html,body{
  margin: 0;
  padding: 0;
  width: 100%;
  height:100%;
}
</style>
